import { useState } from 'react';
import logoCostanet from './assets/images/logo-blanco.png'
import SteepBusqueda from './components/SteepBusqueda';
import SteepMap from './components/SteepMap';
import SteepResultado from './components/SteepResultado';

function App() {
  const [location, setLocation] = useState(null);
  const [mostrarResultado, setmostrarResultado] = useState({ ok: false, lat: 0, lng: 0 });

  const handleLocationChange = (newLocation) => {
    //console.log('Nueva ubicación:', newLocation);
    setLocation(newLocation);
  };

  function volver () {
    setLocation(null)
    console.log('yes')
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={ logoCostanet } className="App-logo" alt="logo costanet+" />
        <h1 className='App-title'>VERIFICA TU COBERTURA</h1>
      </header>
      <div className="App-body">
        {(!location && !mostrarResultado.ok) && <SteepBusqueda onPlaceSelected={handleLocationChange} />}
        {(location && !mostrarResultado.ok) && <SteepMap initialLocation={location} onLocationChangem={setmostrarResultado} volver={volver}/>}
        {mostrarResultado.ok && <SteepResultado latitud={mostrarResultado.lat} longitud={mostrarResultado.lng}/>}
      </div>
    </div>
  );
}

export default App;

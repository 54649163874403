import { useForm } from 'react-hook-form'
import styles from '../assets/css/steepBusqueda.module.css'
import { useState } from 'react'
import { LoadingOval } from './Loadings'
import { urlServer } from '../providers/providers'

const Plan = ({ fondo, color, nombre, mbps, valor, ctvs, width, setPLan, selected}) => {
    return <div className={ `${styles.plan} ${selected ? styles.selected : ''}` } style={{ backgroundColor: fondo, color: color, width: width }} onClick={() => setPLan()}>
        <h2 className={ styles.titlePlan }>{nombre}</h2>
        <p className={ styles.mbpsPlan }>{mbps} Mbps</p>
        <p className={ styles.precioPlan }>${valor}.<sup>{ctvs}</sup></p>
    </div>
}

const FormContratar = ({ sector, latitud, longitud, resuladoEnvio }) => {

    const [ isLoading, setIsLoading ] = useState(false)
    const { register, handleSubmit, formState: { errors }, clearErrors, setValue, watch } = useForm({
        defaultValues: {
            'sector': sector,
            'latitud': latitud,
            'longitud': longitud
        }
    })

    const dataPlanes = [
        { fondo: '#F8B132', color: '#000', nombre: 'Diviértete', valor: '20', ctvs: '00', mbps: '120' },
        { fondo: '#FEEB15', color: '#000', nombre: 'Alócate', valor: '27', ctvs: '00', mbps: '250' },
        { fondo: '#93D0DF', color: '#000', nombre: 'Destruye', valor: '30', ctvs: '00', mbps: '300' },
        { fondo: '#B41828', color: '#fff', nombre: 'Refuérzate', valor: '35', ctvs: '00', mbps: '350' },
        { fondo: 'black', color: '#fff', nombre: 'Vuela', valor: '39', ctvs: '90', mbps: '500' },
    ]

    function setPlanSelected(plan) {
        setValue('plan', plan)
        setValue('isPlanSelected', true)
        clearErrors('isPlanSelected')
        
    }

    const onSubmit = handleSubmit(data => {
        let formData = new FormData()
        formData.append('cedula', data.cedula)
        formData.append('nombre', data.nombre)
        formData.append('apellido', data.apellido)
        formData.append('sector', data.sector)
        formData.append('latitud', data.latitud)
        formData.append('longitud', data.longitud)
        formData.append('correo', data.correo)
        formData.append('celular', data.celular)
        formData.append('comentarios', data.comentarios)
        setIsLoading(true)

        fetch(`${urlServer}/apiweb/utils/newCustomerCobertura`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                if (data.ok) {
                    resuladoEnvio(true)
                }
            })
            .catch(e => console.error(e))
            .finally(_ => setIsLoading(false))
    })

    return <div className={ styles.contratar }>
        <p className={ styles.titleSeccionForm }>Elige un plan a contratar</p>
        <div className={ styles.contPlanes }>
            {dataPlanes.map((plan, index) => <Plan key={index} nombre={plan.nombre} mbps={plan.mbps} valor={plan.valor} ctvs={plan.ctvs} fondo={plan.fondo} color={plan.color} width={ `calc(80% / ${dataPlanes.length})` } setPLan={ () => setPlanSelected(plan.nombre) } selected={ watch('plan') === plan.nombre }/>)}
        </div>
        <input hidden type='checkbox' { ...register('isPlanSelected', {
            required: {
                value: true,
                message: 'Debes seleccionar un plan'
            }
        }) } />
        {errors.isPlanSelected && <span className={ styles.errorFormField }>{ errors.isPlanSelected.message }</span>}
        <p className={ styles.titleSeccionForm } style={{ marginTop: 30 }}>Ingresa tus datos</p>
        <form onSubmit={ onSubmit }>
            <div className={ styles.formGroup }>
                <div className={ styles.formItem }>
                    <label htmlFor='nombre'>Nombre</label>
                    <input type='text' { ...register('nombre', {
                        required: {
                            value: true,
                            message: 'Debes ingresar tu nombre'
                        }
                    }) } placeholder='Nombre'/>
                    {errors.nombre && <span className={ styles.errorFormField }>{ errors.nombre.message }</span>}
                </div>
                <div className={ styles.formItem }>
                    <label htmlFor='apellido'>Apellido</label>
                    <input type='text' { ...register('apellido', {
                        required: {
                            value: true,
                            message: 'Debes ingresar tu apellido'
                        }
                    }) } placeholder='Apellido'/>
                    {errors.apellido && <span className={ styles.errorFormField }>{ errors.apellido.message }</span>}
                </div>
            </div>
            <div className={ styles.formGroup }>
                <div className={ styles.formItem }>
                    <label htmlFor='cedula'>Cédula o RUC</label>
                    <input type='number' { ...register('cedula', {
                        required: {
                            value: true,
                            message: 'Debes ingresar tu cédula o RUC'
                        },
                        pattern: {
                            value: /^[0-9]{10}([0-9]{3})?$/,
                            message: 'La Cédula debe tener 10 dígitos o el RUC 13 dígitos'
                        },
                    }) } placeholder='Cédula o RUC'/>
                    {errors.cedula && <span className={ styles.errorFormField }>{ errors.cedula.message }</span>}
                </div>
                <div className={ styles.formItem }>
                    <label htmlFor='correo'>Correo</label>
                    <input type='email' { ...register('correo', {
                        required: {
                            value: true,
                            message: 'Debes ingresar tu correo'
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                            message: 'El correo ingresado no es válido'
                        },
                    }) } placeholder='Correo'/>
                    {errors.correo && <span className={ styles.errorFormField }>{ errors.correo.message }</span>}
                </div>
            </div>
            <div className={ styles.formGroup }>
                <div className={ styles.formItem }>
                    <label htmlFor='celular'>Celular</label>
                    <input type='text' { ...register('celular', {
                        required: {
                            value: true,
                            message: 'Debes ingresar tu número de celular'
                        },
                        pattern: {
                            value: /^(?:0[2-7][2-9]\d{5}|09\d{8})$/,
                            message: 'El número de teléfono no tiene el formato correcto'
                        },
                    }) } placeholder='Celular'/>
                    {errors.celular && <span className={ styles.errorFormField }>{ errors.celular.message }</span>}
                </div>
                <div className={ styles.formItem }>
                    <label htmlFor='comentarios'>Información adicional (Opcional)</label>
                    <input type='text' { ...register('comentarios', {
                        required: {
                            value: false,
                            message: ''
                        }
                    }) } placeholder='Información adicional (Opcional)'/>
                    {errors.comentarios && <span className={ styles.errorFormField }>{ errors.comentarios.message }</span>}
                </div>
            </div>
            <p className={ styles.footerForm }>Recibiremos tu solicitud y atenderemos tu requerimiento lo antes posible</p>
            { !isLoading ? <button type='submit' className={ styles.btnVerificarCobertura } >¡Adquirir Plan!</button> :
            <LoadingOval show={isLoading} text={'Enviando solicitud espera ...'}/>}
        </form>
    </div>
}

export default FormContratar
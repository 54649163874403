import { useEffect, useState } from 'react'
import styles from '../assets/css/steepBusqueda.module.css'
import { urlServer } from '../providers/providers'
import { LoadingOval } from './Loadings'
import FormContratar from './FormContratar'
const SteepResultado = ({ latitud, longitud }) => {

    const [resultado, setResultado] = useState({ ok: false, sector: '' })
    const [loading, setLoading] = useState(true)
    const [resuladoEnvio, setResuladoEnvio] = useState(false)

    useEffect(() => {
        let formData = new FormData()
        formData.append('latitud', latitud)
        formData.append('longitud', longitud)
        fetch(`${urlServer}/apiweb/utils/comprobarCoberturaCostanet`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                setResultado(data)
            })
            .finally(_ => setLoading(false))
    }, [latitud, longitud])

    return <div className={styles.contSteepBusqueda}>
        <h1 className={styles.title}>{!resuladoEnvio ? 'Verificación de cobertura' : 'Adquirir un plan'}</h1>
        <br />
        <div className={styles.contElements}>
            {loading ? <div className={styles.contLoading}>
                <LoadingOval show={ loading } text={ 'Comprobando cobertura en tu sector espera ...' }/>
            </div> : <>
                <div className={styles.iconResultado}>
                    {(resultado.ok || resuladoEnvio) ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='green' d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='red' d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>}
                </div>
                {!resuladoEnvio ? <div className={ styles.resultadoDiv }>
                    <h2 className={styles.title_res}>{ resultado.ok ? '¡FELICIDADES!' : '¡LO SENTIMOS!'}</h2>
                    <p className={styles.parrafo_res}>{ resultado.ok ? 'Tienes cobertura COSTANET+' : 'De momento no tenemos cobertura en ésta ubicación' }</p>
                </div> :
                <div className={ styles.resultadoDiv }>
                    <h2 className={styles.title_res}>¡GRACIAS!</h2>
                    <p className={styles.parrafo_res}>POR ADQUIRIR NUESTROS SERVICIOS</p>
                </div>}
                { resuladoEnvio && <p className={ styles.descripcion_res }>En brebe un asesor se comunicará contigo para finalizar la adquisición de tu plan de internet</p> }
                {(!resultado.ok || resuladoEnvio ) && <button onClick={ () => window.location.reload() } className={ styles.btnVerificarCobertura }>Ingresar otra ubicación</button>}
                {(resultado.ok && !resuladoEnvio) && <FormContratar sector={ resultado.sector } latitud={ latitud } longitud={ longitud } resuladoEnvio={setResuladoEnvio}/>}
            </>}
        </div>
    </div>
}

export default SteepResultado
import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import styles from '../assets/css/steepBusqueda.module.css';

const libraries = ["places"];

const SteepMap = ({ initialLocation, onLocationChangem, volver }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDMjc8r7U-gBgu_80CI5Q0kW0uND-A68S8",
        libraries,
    });

    const mapRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const markerRef = useRef(null);

    useEffect(() => {
        async function initMap() {
            if (isLoaded && mapRef.current) {
                //@ts-ignore
                const { Map } = await window.google.maps.importLibrary("maps");
                const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");

                mapInstanceRef.current = new Map(mapRef.current, {
                    zoom: 15,
                    center: initialLocation,
                    mapId: "COSTANET_MAP_ID",
                });

                markerRef.current = new AdvancedMarkerElement({
                    map: mapInstanceRef.current,
                    position: initialLocation,
                    title: "Ubicación seleccionada",
                    gmpDraggable: true,
                });

                const handleDragEnd = () => {
                    const newPosition = markerRef.current.position;
                    onLocationChangem({ lat: newPosition.lat, lng: newPosition.lng });
                };

                markerRef.current.addListener('dragend', handleDragEnd);
            }
        }

        initMap();
    }, [isLoaded, initialLocation, onLocationChangem]);

    const handleCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    mapInstanceRef.current.setCenter(newLocation);
                    markerRef.current.position = newLocation
                    onLocationChangem(newLocation)
                },
                (error) => {
                    console.error("Error obteniendo la ubicación", error);
                }
            );
        } else {
            console.error("Geolocalización no es soportada por este navegador.");
        }
    };

    if (loadError) return <div>Error al cargar el mapa</div>;
    if (!isLoaded) return <div>Cargando...</div>;

    return (
        <div className={styles.contSteepBusqueda}>
            <h1 className={styles.title}><button className={ styles.backIcon } title='Volver' onClick={volver}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                </button>Agrega la ubicación de tu domicilio en el mapa para verificar cobertura</h1>
            <p className={styles.descripcion}>Verifica tu dirección</p>
            <div className={styles.contElements} style={{ position: "relative"}}>
                <div ref={mapRef} style={{ width: "100%", height: "400px"}}></div>
                <button onClick={handleCurrentLocation} className={styles.btnUbicacion}>
                    <div className={ styles.locIcon }>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='rgb(58, 58, 58)' d="M256 0c17.7 0 32 14.3 32 32l0 34.7C368.4 80.1 431.9 143.6 445.3 224l34.7 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-34.7 0C431.9 368.4 368.4 431.9 288 445.3l0 34.7c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.7C143.6 431.9 80.1 368.4 66.7 288L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l34.7 0C80.1 143.6 143.6 80.1 224 66.7L224 32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg>
                    </div>
                </button>
                <p className={styles.tolTipText}>Puedes arrastrar la posición del pin para tener mayor precisión</p>
                <button
                    onClick={() =>
                        onLocationChangem({
                            lat: markerRef.current.position.lat,
                            lng: markerRef.current.position.lng,
                            ok: true,
                        })
                    }
                    className={styles.btnVerificarCobertura}
                >
                    Verificar Cobertura
                </button>
            </div>
        </div>
    );
};

export default SteepMap;

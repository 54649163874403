import { useEffect, useRef, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import styles from '../assets/css/steepBusqueda.module.css'
const libraries = ["places"];

const SteepBusqueda = ({ onPlaceSelected }) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDMjc8r7U-gBgu_80CI5Q0kW0uND-A68S8",
        libraries,
        loadingElement: <div>Cargando...</div>,
    });

    const [inputValue, setInputValue] = useState("");
    const [loadingMiUbicacion, setLoadingMiUbicacion] = useState(false);
    const [loadingMiUbicacionError, setLoadingMiUbicacionError] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const autocompleteService = useRef(null);
    const geocoder = useRef(null);

    useEffect(() => {
        if (isLoaded) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
            geocoder.current = new window.google.maps.Geocoder();
        }
    }, [isLoaded]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (value.length > 3) {
            autocompleteService.current.getPlacePredictions({ input: value }, (predictions, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setSuggestions(predictions);
                } else {
                    setSuggestions([]);
                }
            });
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (description) => {
        setInputValue(description);
        setSuggestions([]);

        // Obtener coordenadas del lugar seleccionado
        geocoder.current.geocode({ address: description }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                const location = results[0].geometry.location;
                onPlaceSelected({
                    lat: location.lat(),
                    lng: location.lng(),
                    address: description,
                });
            }
        });
    };

    const handleUseCurrentLocation = () => {
        setLoadingMiUbicacion(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    onPlaceSelected({
                        lat: latitude,
                        lng: longitude,
                        address: 'Mi ubicación actual',
                    });
                },
                (error) => {
                    setLoadingMiUbicacionError('No se condedieron los permisos de ubicación, actívalos o ingresa manualmente la dirección')
                    console.error('Error al obtener la ubicación:', error);
                }
            );
        } else {
            setLoadingMiUbicacionError('Este navegador no soporta esta función, ingresa manualmente la dirección')
        }
        //setLoadingMiUbicacion(false)
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
            handleSuggestionClick(suggestions[activeSuggestionIndex].description);
        }
    };

    return <div className={styles.contSteepBusqueda}>
        <h1 className={styles.title}>Agrega la ubicación de tu domicilio en el mapa para verificar cobertura </h1>
        <p className={styles.descripcion}>Ingresa una dirección</p>
        <div className={styles.contElements}>
            <input className={styles.inputDireccion} type='text' placeholder='Escribe una dirección' value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} />
            <ul className={styles.contSugerencias}>
                {suggestions?.map((suggestion, index) => (
                    <li key={suggestion.place_id} className={`${styles.divider} ${index === activeSuggestionIndex ? styles.hover : ''}`} onClick={() => handleSuggestionClick(suggestion.description)}>{suggestion.description}</li>
                ))}
            </ul>
            {!loadingMiUbicacion ? <button className={styles.buttonUbi} onClick={handleUseCurrentLocation}>
                <div className={styles.iconButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='white' d="M256 0c17.7 0 32 14.3 32 32l0 34.7C368.4 80.1 431.9 143.6 445.3 224l34.7 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-34.7 0C431.9 368.4 368.4 431.9 288 445.3l0 34.7c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.7C143.6 431.9 80.1 368.4 66.7 288L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l34.7 0C80.1 143.6 143.6 80.1 224 66.7L224 32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg>
                </div>
                <span>Usar mi ubicación actual</span>
            </button> :
                <button className={styles.buttonUbi} style={{ backgroundColor: '#cccccc' }}><span>Obteniendo tu ubicación actual ...</span></button>}
            {loadingMiUbicacionError !== '' && <p className={styles.errorGetLocate}>{loadingMiUbicacionError}</p>}
        </div>
    </div>
}

export default SteepBusqueda